.title {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 900;
  font-family: Inter, sans-serif;
}
.lead {
  color: #777;
  font-size: 1rem;
  font-family: Inter, sans-serif;

}

.reader-credits {
  color: #777;
  font-size: 1rem;
  font-style: italic;
  font-family: Inter, sans-serif;

}

p {
  padding-bottom: 1rem;
}